import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, useTheme, Text } from '@chakra-ui/core';
import BackgroundImage from 'gatsby-background-image';
import HeroBooking from './hero-booking';
import { mediaQueries } from '../../helpers';

const HeroContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  min-height: 100vh;
  margin: 0px auto;
  padding-top: 64px;

  ${mediaQueries.laptop(`
    padding-top: 0px;
    flex-direction: column-reverse;
    min-height: 120vh;
  `)}
`;

const StyledTitle = styled(Text)`
  font-size: 4em;
  text-align: center;

  ${mediaQueries.tablet(`
    font-size: 3em;
  `)}
`;

const HeroBanner = ({ siteTitle }) => {
  const theme = useTheme();
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43))`,
    data.placeholderImage.childImageSharp.fluid,
  ];

  return (
    <BackgroundImage
      Tag="section"
      fluid={backgroundFluidImageStack}
      backgroundColor={theme.colors.gray[500]}
      alt="Photo de la facade d'un de nos appartements"
    >
      <HeroContentContainer>
        <HeroBooking />
        <Box color={theme.colors.gray[100]}>
          <StyledTitle className="title-font">
            LE COCOON
            <br /> DE SARAH
          </StyledTitle>
        </Box>
      </HeroContentContainer>
    </BackgroundImage>
  );
};

export default HeroBanner;
