import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import styled from 'styled-components';
import { Box, Heading, Text, Avatar, AvatarBadge } from '@chakra-ui/core';
import { FaAirbnb } from 'react-icons/fa';
import { DiBootstrap } from 'react-icons/di';

import { mediaQueries } from '../../helpers';

const Container = styled.div`
  width: 100%;
  margin: 40px 0px;
  padding: 0px 16px;

  ${mediaQueries.mobile(`
    margin: 16px 0px;
  `)}
`;

const TestimonialCard = styled.div`
  margin: 16px;
  padding: 32px 24px;
  box-shadow: 2px 3px 5px rgba(28, 26, 28, 0.2);
  border: 1px solid rgba(28, 26, 28, 0.05);
  border-radius: 4px;

  ${mediaQueries.laptop(`
    width: 65%;
  `)}

  ${mediaQueries.tablet(`
    width: 100%;
  `)}

  ${mediaQueries.mobile(`
    margin: 0;
  `)}
`;

const TESTIMONIALS_MAX_COUNT = 9;

class TestimonialsCarousel extends Component {
  state = {
    value: 0,
    isLessThanDesktopWidth: true,
  };

  componentDidMount() {
    this.checkWindowSize();
  }

  onChange = (value, max) => {
    const newValue = value >= max ? 1 : value;
    this.setState({ value: newValue });
  };

  checkWindowSize = () => {
    const mq = window.matchMedia('(max-width: 1024px)');
    this.setState({ isLessThanDesktopWidth: mq.matches });
  };

  render() {
    const { testimonials = [] } = this.props;
    const { value, isLessThanDesktopWidth } = this.state;

    if (!testimonials || !testimonials.length) {
      return null;
    }

    const testimonialsToShow =
      testimonials.length > TESTIMONIALS_MAX_COUNT
        ? testimonials.slice(0, TESTIMONIALS_MAX_COUNT)
        : testimonials;

    if (typeof window !== 'undefined') {
      const mq = window.matchMedia('(max-width: 1024px)');

      mq.addListener(() => {
        this.setState({ isLessThanDesktopWidth: mq.matches });
      });
    }

    return (
      <Container>
        <Carousel
          slidesPerPage={isLessThanDesktopWidth ? 1 : 3}
          value={value}
          onChange={this.onChange}
          offset={isLessThanDesktopWidth ? 0 : 8}
        >
          {testimonialsToShow.map((item, index) => {
            const color = item.from === 'booking' ? 'blue.600' : 'tomato';
            const Icon = item.from === 'booking' ? DiBootstrap : FaAirbnb;

            return (
              <TestimonialCard key={index}>
                <Text fontSize="lg" textAlign="center" color="gray.500">
                  {item.comment}
                </Text>
                <Box display="flex" alignItems="center" marginTop="24px">
                  <Avatar size="md" name={item.name} src={item.pictureURL}>
                    <AvatarBadge
                      as={Icon}
                      size="1.25em"
                      padding="1px"
                      backgroundColor={color}
                      color="white"
                    />
                  </Avatar>
                  <Heading fontSize="md" marginLeft="24px">
                    {item.name}
                  </Heading>
                </Box>
              </TestimonialCard>
            );
          })}
        </Carousel>
        <Dots
          value={value}
          onChange={value =>
            this.onChange(value, testimonialsToShow.length - 2)
          }
          number={testimonialsToShow.length - 2}
        />
      </Container>
    );
  }
}

const Testimonials = (props = {}) => {
  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      site {
        siteMetadata {
          testimonials {
            name
            from
            rating
            pictureURL
            comment
          }
        }
      }
    }
  `);

  return (
    <TestimonialsCarousel testimonials={data.site.siteMetadata.testimonials} />
  );
};

export default Testimonials;
