import React from 'react';
import styled from 'styled-components';
import { Heading, Text, Box, Image, useTheme } from '@chakra-ui/core';

import Layout from '../components/skeleton/layout';
import SEO from '../components/skeleton/seo';
import HeroBanner from '../components/home/hero-banner';
import HouseGrid from '../components/home/house-grid';
import Testimonials from '../components/home/testimonials';

import { mediaQueries } from '../helpers';

const BioContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  padding: 40px 48px;
  padding-bottom: 0;

  ${mediaQueries.laptop(`
    grid-template-columns: repeat(1, 1fr);
  `)}

  ${mediaQueries.mobile(`
    padding: 16px 8px;
  `)}
`;

const TextContainer = styled.div`
  ${mediaQueries.laptop(`
    margin-top: 32px;
  `)}
`;

const Container = styled.div`
  margin: 48px 0;

  ${mediaQueries.mobile(`
    margin-top: 8px;
  `)}
`;

const AirbnbContainer = styled.div`
  justify-self: center;
  align-self: center;
  padding: 0px 24px;

  ${mediaQueries.mobile(`
    padding: 0px 8px;
  `)}
`;

const AirbnbBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  width: 100%;

  ${mediaQueries.mobile(`
    padding: 0px;
  `)}
`;

const BioPictureContainer = styled.div`
  display: block;
  align-self: center;
  justify-self: center;
  overflow: hidden;
  border-radius: 4px;
  min-height: 370px;

  ${mediaQueries.laptop(`
    max-width: 80%;
    margin-top: 32px;
  `)}

  ${mediaQueries.mobile(`
    display: none;
  `)}
`;

const StyledText = styled(Text)`
  font-size: 20px;
  margin-bottom: 24px;
  text-align: center;
  color: ${props => props.color};

  ${mediaQueries.mobile(`
    font-size: 18px;
    text-align: justify;
  `)}
`;

const IndexPage = () => {
  const theme = useTheme();
  const [hovered, setHover] = React.useState(false);

  return (
    <Layout headerChildren={<HeroBanner />}>
      <SEO title="Location appartements Chamonix" />

      <Container>
        <Heading marginBottom="16px" textAlign="center" color="gray.600">
          Quelques avis de nos clients
        </Heading>
        <Testimonials />
      </Container>

      <Container>
        <Heading marginBottom="16px" textAlign="center" color="gray.600">
          Des locations d'appartements pour vos vacances à Chamonix mont-blanc
        </Heading>
        <HouseGrid />
      </Container>

      <Container>
        <Heading
          as="h1"
          marginBottom="16px"
          textAlign="center"
          color="gray.600"
        >
          Une réelle expertise pour embellir vos vacances à Chamonix mont-blanc
        </Heading>

        <BioContainer>
          <AirbnbContainer>
            <TextContainer>
              <StyledText color={theme.colors.gray[500]}>
                Nous mettons tout en oeuvre pour que nos hébergements et notre
                service vous permettent de passer les meilleures vacances à
                Chamonix mont-blanc.
              </StyledText>
            </TextContainer>

            <AirbnbBox>
              <a
                href="https://www.airbnb.fr/users/show/86333720"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <Image
                    width="100%"
                    src="https://res.cloudinary.com/dzba82asr/image/upload/v1706626705/lecoccondesarah/airbnb.jpg"
                  />
                </Box>
              </a>
            </AirbnbBox>
          </AirbnbContainer>
          <BioPictureContainer>
            <Image
              justifySelf="center"
              align-self="stretch"
              maxWidth="450px"
              src="https://res.cloudinary.com/dzba82asr/image/upload/c_thumb,w_400,g_face/v1586779078/lecoccondesarah/booking-award.png"
            />
          </BioPictureContainer>
        </BioContainer>
      </Container>
    </Layout>
  );
};

export default IndexPage;
