import React from 'react';
import { Box, Text } from '@chakra-ui/core';
import styled from 'styled-components';
import { HouseCard } from '../common';
import APPARTS from '../../static-data/appartements';
import { mediaQueries } from '../../helpers';

const HouseCardWrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => (props.isReversed ? 'row-reverse' : 'row')};

  ${mediaQueries.laptop(`
    flex-direction: column;
  `)}
`;

const TextWrapper = styled.div`
  max-width: 40%;

  ${mediaQueries.laptop(`
    max-width: 90%;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 16px;
  `)};
`;

const HouseGrid = () => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      justifyContent="center"
    >
      {APPARTS.map((item, index) => (
        <HouseCardWrapper isReversed={index % 2 === 0 ? false : true}>
          <HouseCard property={item} key={index} />
          <TextWrapper>
            <Text
              fontSize="md"
              color="gray.500"
              lineHeight="1.6em"
              marginBottom="16px"
            >
              {item.intro}
            </Text>
            <Text fontSize="md" color="gray.500" lineHeight="1.6em">
              {item.description}
            </Text>
          </TextWrapper>
        </HouseCardWrapper>
      ))}
    </Box>
  );
};

export default HouseGrid;
