import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useTheme, Heading, Button } from '@chakra-ui/core';
import { DateRangeInput } from '@datepicker-react/styled';
import { format } from 'date-fns';
import { fr as locale } from 'date-fns/locale';

import { mediaQueries } from '../../helpers';

const Wrapper = styled.div`
  padding: 32px 24px;
  flex: 1;
  border-radius: 4px;
  background-color: ${props => props.colors.white};
  max-width: 500px;
  max-height: 450px;
  width: 100%;
  justify-content: flex-start;
  margin-right: 32px;
  box-shadow: 2px 3px 5px rgba(28, 26, 28, 0.2);

  ${mediaQueries.laptop(`
    margin: 32px auto;
  `)}
`;

const initialState = {
  startDate: null,
  endDate: null,
  focusedInput: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'focusChange':
      return { ...state, focusedInput: action.payload };
    case 'dateChange':
      return action.payload;
    default:
      throw new Error();
  }
}

const HeroBooking = (props = {}) => {
  const today = new Date();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const theme = useTheme();

  const onConfirmDates = () => {
    const { startDate, endDate } = state;

    if (startDate && endDate) {
      navigate('/booking', {
        state: {
          startDate,
          endDate,
        },
      });
    }
  };

  let width;

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    width =
      window.screen.width ||
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  }

  return (
    <Wrapper colors={theme.colors}>
      <Heading fontSize="2xl" mb="48px" color={theme.colors.gray[600]}>
        Location appartements de vacances à Chamonix mont-blanc
      </Heading>
      <DateRangeInput
        onDatesChange={data => dispatch({ type: 'dateChange', payload: data })}
        onFocusChange={focusedInput =>
          dispatch({ type: 'focusChange', payload: focusedInput })
        }
        startDate={state.startDate} // Date or null
        endDate={state.endDate} // Date or null
        focusedInput={state.focusedInput} // START_DATE, END_DATE or null
        minBookingDate={today}
        minBookingDays={3}
        displayFormat="dd/MM/yyyy"
        phrases={{
          datepickerStartDatePlaceholder: 'Sélectionner',
          datepickerStartDateLabel: "Date d'arrivée:",
          datepickerEndDatePlaceholder: 'Sélectionner',
          datepickerEndDateLabel: 'Date de départ:',
          resetDates: 'Reinitialiser',
          startDateAriaLabel: "Date d'arrivée",
          endDateAriaLabel: 'Date de départ',
          startDatePlaceholder: "Date d'arrivée",
          endDatePlaceholder: 'Date de départ',
          close: 'Fermer',
        }}
        dayLabelFormat={date => format(date, 'dd', { locale })}
        weekdayLabelFormat={date => format(date, 'eeeeee', { locale })}
        monthLabelFormat={date => format(date, 'MMMM yyyy', { locale })}
        vertical={width <= 375 ? true : false}
      />

      <Button
        rightIcon="arrow-forward"
        variantColor="gray200"
        variant="outline"
        mt="32px"
        onClick={onConfirmDates}
      >
        Réserver
      </Button>
    </Wrapper>
  );
};

export default HeroBooking;
